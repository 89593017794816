<template>
  <a :href="station.details">
      <div :class="color">
        <div class="row">
          <div :class="color1">
            <div class="text-center"><span class="station"><b>{{ station.title }} </b></span> <span class="attitude"> ({{ station.attitude }} m)</span></div>
          </div>
        </div>
        <div class="row">
          <div :class="color2">
            <img class="img img-fluid" :src="station.url" alt="image" />
          </div>
        </div>
        <div class="row">
            <div :class="color3">
                <div class="mb-3"><b>Tip vremenske postaje:</b> {{ station.stationType }} <br /> <b>Zadnja osvežitev: </b>
                <span v-if="station.refreshDate !== null"> {{ station.refreshDate.substring(0, 8) }} ob {{ station.refreshDate.substring(13, 18) }}</span><span v-else> ---</span></div>
            </div>
        </div>
        <div class="row text-center">
            <div :class="color4">
                <div class="p-4 variable"><span v-if="station.temp !== null">{{ station.temp }} °C</span><span v-else>---</span></div>
            </div>
             <div :class="color5">
                <div class="p-4 variable"><span v-if="station.hum !== null">{{ station.hum }} %</span><span v-else>---</span></div>
            </div>
        </div>
      </div>
  </a>
</template>

<script>
import moment from 'moment';

export default {
    name: 'Station',
    data() {
        return {
            color: '',
            color1: '',
            color2: '',
            color3: '',
            color4: ''
        };
    },
    mounted() {
        if (this.station.title == 'RIBNICA') {
            this.color = `container border-start border-top border-end ${this.station.color} border-4`
            this.color1 = `col-12 col-md-12 border-end border-bottom border-start ${this.station.color} border-4`
            this.color2 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 ps-1 pe-1 text-center`
            this.color3 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 mt-2`
            this.color4 = `col-12 col-md-6 border-end ${this.station.color} border-4 border-bottom`
            this.color5 = `col-12 col-md-6 ${this.station.color} border-4 border-bottom`
        }
        else if (this.station.title == 'PRIGORICA') {
            this.color = `container border-start border-top border-end ${this.station.color} border-4`
            this.color1 = `col-12 col-md-12 border-end border-bottom border-start ${this.station.color} border-4`
            this.color2 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 ps-1 pe-1 text-center`
            this.color3 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 mt-2`
            this.color4 = `col-12 col-md-6 border-end ${this.station.color} border-4 border-bottom`
            this.color5 = `col-12 col-md-6 ${this.station.color} border-4 border-bottom`
        }
        else if (this.station.title == 'GAŠPINOVO') {
            this.color = `container border-start border-top border-end ${this.station.color} border-4`
            this.color1 = `col-12 col-md-12 border-end border-bottom border-start ${this.station.color} border-4`
            this.color2 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 ps-1 pe-1 text-center`
            this.color3 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 mt-2`
            this.color4 = `col-12 col-md-6 border-end ${this.station.color} border-4 border-bottom`
            this.color5 = `col-12 col-md-6 ${this.station.color} border-4 border-bottom`
        }
        else if (this.station.title == 'SVETA ANA') {
            this.color = `container border-start border-top border-end ${this.station.color} border-4`
            this.color1 = `col-12 col-md-12 border-end border-bottom border-start ${this.station.color} border-4`
            this.color2 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 ps-1 pe-1 text-center`
            this.color3 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 mt-2`
            this.color4 = `col-12 col-md-6 border-end ${this.station.color} border-4 border-bottom`
            this.color5 = `col-12 col-md-6 ${this.station.color} border-4 border-bottom`
        }
        else if (this.station.title == 'HRIB') {
            this.color = `container border-start border-top border-end ${this.station.color} border-4`
            this.color1 = `col-12 col-md-12 border-end border-bottom border-start ${this.station.color} border-4`
            this.color2 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 ps-1 pe-1 text-center`
            this.color3 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 mt-2`
            this.color4 = `col-12 col-md-6 border-end ${this.station.color} border-4 border-bottom`
            this.color5 = `col-12 col-md-6 ${this.station.color} border-4 border-bottom`
        }
        else if (this.station.title == 'RETJE') {
            this.color = `container border-start border-top border-end ${this.station.color} border-4`
            this.color1 = `col-12 col-md-12 border-end border-bottom border-start ${this.station.color} border-4`
            this.color2 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 ps-1 pe-1 text-center`
            this.color3 = `col-12 col-md-12 border-bottom ${this.station.color} border-4 mt-2`
            this.color4 = `col-12 col-md-6 border-end ${this.station.color} border-4 border-bottom`
            this.color5 = `col-12 col-md-6 ${this.station.color} border-4 border-bottom`
        }
    },
    methods: {
        moment
    },
    props: {
        station: Object
    }
}
</script>

<style scoped>
.station {
    font-size: 22px;
}

.attitude {
    font-size: 19px;
}

.variable {
    font-size: 20px;
    font-weight: 500;
}

@media (max-width: 576px) { 
    
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
}
</style>

